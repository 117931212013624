/* eslint-disable no-trailing-spaces */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import {
    DTO, DTOMetaData, DTOMethods, DTOClassMethods,
    BoundEndpointAPI, Attachment, EventAttachment
} from '../dto';

import { Event_v1 } from './event-v1';
import { EventType } from './eventtype';

export interface Meeting_v1 extends DTO, DTOMethods<Event_v1> {
    eventType: EventType;
    subType: string;
    startDate: Date;
    endDate: Date;
    isAllDay: boolean;
    status: string;
    priority: number;
    rank: number;
    createdDate: Date;
    duration: number;
    durationType: string;
    durationUnit: string;
    startTimezone: string;
    endTimezone: string;
    location: string;
    recurrenceId: number;
    recurrenceRule: string;
    recurrenceException: string;
    files: EventAttachment[];
    resolvedUsers: Attachment[];
}
export const Meeting_v1: DTOMetaData & DTOClassMethods<Meeting_v1> = {
    _label:'Meetings',
    _dto: 'Meeting_v1',
    _type: 'meeting',
    _colors: { primaryThemeColor: "#6d9b44", secondaryThemeColor: "#558a1f", tertiaryThemeColor: "#558a1f" },
    _keysets: ["id"], // TODO: Generate from DTO Schema
    _properties: [
        { label: 'Event type', prop: 'eventType', hint: 'Event type', type: 'enum', fixed: false, source: undefined, values: EventType, default: EventType.TASK },
        { label: 'Sub type', prop: 'subType', hint: 'Text (max 64k chars)', type: 'string', fixed: true, source: undefined, values: undefined, default: "" },
        { label: 'Start date', prop: 'startDate', hint: 'Date and time', type: 'Date', fixed: true, source: undefined, values: undefined, default: new Date() },
        { label: 'End date', prop: 'endDate', hint: 'Date and time', type: 'Date', fixed: true, source: undefined, values: undefined, default: new Date() },
        { label: 'Is all day', prop: 'isAllDay', hint: 'Yes/No', type: 'boolean', fixed: true, source: undefined, values: undefined, default: false },
        { label: 'Status', prop: 'status', hint: 'Text (max 64k chars)', type: 'string', fixed: true, source: undefined, values: undefined, default: "New" },
        { label: 'Priority', prop: 'priority', hint: 'Integer value (-2147483648..2147483647)', type: 'number', fixed: true, source: undefined, values: undefined, default: 2 },
        { label: 'Rank', prop: 'rank', hint: 'Integer value (-2147483648..2147483647)', type: 'number', fixed: true, source: undefined, values: undefined, default: 0 },
        { label: 'Created date', prop: 'createdDate', hint: 'Date and time', type: 'Date', fixed: true, source: undefined, values: undefined, default: new Date() },
        { label: 'Completed date', prop: 'completedDate', hint: 'Date and time', type: 'Date', fixed: true, source: undefined, values: undefined, default: new Date() },
        { label: 'Duration', prop: 'duration', hint: 'Decimal value (7 decimals)', type: 'number', fixed: true, source: undefined, values: undefined, default: 1 },
        { label: 'Duration type', prop: 'durationType', hint: 'Duration type', type: 'enum', fixed: false, source: undefined, values: ["FIXEDDURATION", "FIXEDEND"], default: "FIXEDEND" },
        { label: 'Duration unit', prop: 'durationUnit', hint: 'Duration unit', type: 'enum', fixed: false, source: undefined, values: ["HOUR", "DAY", "WEEK", "MONTH", "QUARTER", "YEAR"], default: "DAY" },
        { label: 'Start timezone', prop: 'startTimezone', hint: 'Text (max 64k chars)', type: 'string', fixed: true, source: undefined, values: undefined, default: "" },
        { label: 'End timezone', prop: 'endTimezone', hint: 'Text (max 64k chars)', type: 'string', fixed: true, source: undefined, values: undefined, default: "" },
        { label: 'Location', prop: 'location', hint: 'Text (max 64k chars)', type: 'string', fixed: true, source: undefined, values: undefined, default: "" },
        { label: 'Recurrence id', prop: 'recurrenceId', hint: 'Integer value (-2147483648..2147483647)', type: 'number', fixed: true, source: undefined, values: undefined, default: 0 },
        { label: 'Recurrence rule', prop: 'recurrenceRule', hint: 'Text (max 64k chars)', type: 'string', fixed: true, source: undefined, values: undefined, default: "" },
        { label: 'Recurrence exception', prop: 'recurrenceException', hint: 'Text (max 64k chars)', type: 'string', fixed: true, source: undefined, values: undefined, default: "" },
    ],
    _related: [
        { prop: 'resolvedUsers', type: 'unknown', class: 'Unknown', label: 'Resolved users' },
     ],
    _endpoint: '/api/eda/v2.0/meeting/v1.0',
    _icon: './assets/dtos/meeting.svg',
    _docLink: '/wiki/elevate/Assets/meeting/',
    _depth: 1,
    endpointAPI: new BoundEndpointAPI<Meeting_v1>('Meeting_v1', DTO),
    from: (obj: any): Meeting_v1 => {
        return Object.setPrototypeOf(obj, Meeting_v1._prototype);
    },
    select: (p: any = ''): any => {
        return Meeting_v1.endpointAPI.get(p).then(res =>
            !res ? undefined
            : Array.isArray(res) ? res.map(a => Meeting_v1.from(a) as Meeting_v1)
            : Meeting_v1.from(res)
    )}
}
